@import "../node_modules/bootstrap/dist/css/bootstrap";

/* 

#edcf8f #fdd662 #885432

*/

/*****************************/
/* HEADER */
/*****************************/

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #885432;
  height: 8.4rem;
  padding: 0 4.8rem;
  position: relative;
}

.header-logo-box {
  display: flex;
  align-items: center;
  gap: 3.2rem;
}

.company-name {
  font-size: 3rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.75px;
  color: #ddd;
  margin-bottom: 0;
}

.logo {
  height: 6.2rem;
}

/*****************************/
/* NAVIGATION */
/*****************************/

.main-nav-list {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
}

.main-nav-link {
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 1.2rem 2.4rem;
}

.main-nav-list li:nth-child(5) {
  margin-right: 2.4rem;
}

.main-nav-link:link,
.main-nav-link:visited {
  display: inline-block;
  text-decoration: none;
  color: #ddd;
  font-weight: 500;
  font-size: 1.8rem;
  transition: all 0.3s;
  border-radius: 9px;
}

.main-nav-link:hover,
.main-nav-link:active {
  color: #fdd662;
  background-color: #653b24;
  border-radius: 9px;
}

.main-nav .nav-cta {
  padding: 1.2rem 2.4rem;
  background-color: #fcc419;
  border-radius: 9px;
  color: #333;
}

.main-nav .nav-cta:hover,
.main-nav .nav-cta:active {
  background-color: #f57600;
  color: #eee;
}

.sticky .header {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 8rem;
  padding-top: 0;
  padding-bottom: 0;
  background-color: rgba(101, 59, 36, 0.97);
  z-index: 999;
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.03);
}

.sticky .section-main {
  margin-top: 8.4rem;
}

.btn-mobile-nav {
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: none;
}

.icon-mobile-nav {
  height: 4.8rem;
  width: 4.8rem;
  color: #333;
}

.icon-mobile-nav[name="close-outline"] {
  display: none;
}

.no-scroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
}

/*****************************/
/* MAIN                      */
/*****************************/

.section-main {
  /* background-color: #edcf8f; */
  /* padding: 4.8rem 0 9.6rem 0; */
  background-color: #eee;
  padding-bottom: 8.4rem;
  border-bottom: 0.1px solid #0000001f;
}

.main {
  padding: 0 3.2rem;
  display: grid;
  align-items: center;
  grid-template-columns: 3fr 2fr;
  background-image: linear-gradient(
      to right,
      rgba(34, 34, 34, 0.6),
      rgba(34, 34, 34, 0.6)
    ),
    url(../img/roofs/2.webp);
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 80vh;
}

.main-text-box {
  margin-left: 4rem;
  color: #ddd;
}

.main-text-description {
  font-size: 2rem;
  line-height: 1.6;
  padding-bottom: 4.2rem;
}

/*****************************/
/* SERVICES                  */
/*****************************/

.section-services {
  padding: 8.4rem 0 8.4rem 0;
  background-color: #eee;
}

.service-text-box {
  border-top: 1rem solid #fdd662;
  padding: 2.4rem;
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.075);
  border-radius: 11px;
  overflow: hidden;
  transition: all 0.4s;
  position: relative;
}

.service-text-box:hover {
  transform: translateY(-1.2rem);
  box-shadow: 0 3.2rem 6.4rem rgba(0, 0, 0, 0.06);
}

.service-icon {
  display: inline-block;
  font-size: 3rem;
  padding: 1.2rem;
  background: #ccc;
  border-radius: 50%;
  color: #0000ff;
  margin-bottom: 2.4rem;
}

.service-text-box:hover .service-description {
  display: block;
  max-height: 1000px;
  transition: all 1s ease;
}

.service-description {
  font-size: 1.8rem;
  line-height: 1.6;
}

.padding-bottom {
  padding-bottom: 3rem;
}

/*****************************/
/* ISSUES                 */
/*****************************/
.slider {
  background-color: #333;
  padding: 3rem 6rem;
}
.section-issues {
  padding: 8.4rem 0 8.4rem 0;
  background-color: #edcf8f;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fdd662' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
  height: 4rem;
  width: 4rem;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fdd662' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
  height: 4rem;
  width: 4rem;
}

.carousel-caption {
  background-color: #333;
  font-size: 2rem;
  padding: 2rem 0;
  /* margin: 3rem 0; */
}
/*****************************/
/* ABOUT                 */
/*****************************/

.section-about {
  padding: 8.4rem 0 8.4rem 0;
  background-color: #eee;
  border: 0;
  border-radius: 2rem;
}

.about-us-container {
  background-color: #62350f;
  border-radius: 9px;
  overflow: hidden;
}

.about-text-box {
  padding: 2.5rem;
  font-size: 2.4rem;
  line-height: 1.5;
  color: #fff;
}

.about-img {
  max-width: 100%;
  display: block;
  background-position: center;
}

/*****************************/
/* Contact SECTION */
/*****************************/
.section-contact {
  padding: 8.4rem 0 12.6rem 0;
  background-color: #edcf8f;
}

.contact {
  display: grid;
  grid-template-columns: 2fr 1.3fr;
  background-color: #885432;
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.15);
  border-radius: 11px;
  overflow: hidden;
}

.contact-text-box {
  padding: 4.8rem 6.4rem 6.4rem 6.4rem;
  color: #fff;
}

.contact-text-box .heading-secondary {
  color: #45260a;
  margin-bottom: 3.2rem;
}

.contact-heading {
  font-size: 3.2rem;
  margin-bottom: 2.4rem;
}

.contact-text {
  font-size: 1.8rem;
  line-height: 1.8;
  margin-bottom: 4.8rem;
}

.contact-email {
  color: #fdd662;
  font-size: 1.5rem;
}

.contact-img-box {
  background-image: linear-gradient(
      to right bottom,
      rgba(235, 151, 78, 0.35),
      rgba(230, 125, 34, 0.35)
    ),
    url("../img/roofs/10.webp");
  background-size: cover;
  background-position: center;
}

.contact-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 3.2rem;
  row-gap: 2.4rem;
}

.contact-form label {
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 1.2rem;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 1.2rem;
  font-size: 1.8rem;
  font-family: inherit;
  border: none;
  color: #000;
  background-color: #fdf2e9;
  border-radius: 9px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.contact-form input::placeholder {
  color: #aaa;
}

.message-box {
  grid-column: 1/-1;
}
#message {
  height: 20rem;
  vertical-align: top;
}

#thank-you-message {
  font-size: 1.8rem;
}

.contact *:focus {
  outline: none;
  box-shadow: 0 0 0 0.8rem rgba(253, 242, 233, 0.5);
}

.btn--form:hover {
  background-color: #f57600;
}

/*****************************/
/* FOOTER SECTION */
/*****************************/

.footer {
  padding: 8.4rem 0;
  background-color: #eee;
}

.footer-grid {
  align-items: top;
}

.logo-col {
  display: flex;
  flex-direction: column;
}

.copyright {
  font-size: 1.4rem;
  color: #767676;
  line-height: 1.6;
  margin-top: auto;
}

.footer-heading {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 4rem;
}

.main-address-col,
.mailing-address-col,
.contact-col {
  font-style: normal;
  font-size: 1.6rem;
  line-height: 1.6;
}

@media (max-width: 1378px) {
  .company-name {
    font-size: 2rem;
  }

  /* .main-nav-link {
    padding: 0.6rem 1.2rem;
  } */
}

@media (max-width: 1180px) {
  .about-text-box {
    font-size: 2rem;
  }
}

@media (max-width: 1030px) {
  .about-text-box {
    font-size: 1.6rem;
  }

  .grid--3-cols {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 900px) {
  .btn--full {
    margin-bottom: 2rem;
  }
  .main-text-box {
    grid-column: 1/-1;
  }
  .issue {
    padding: 3rem 6rem;
  }
  .issue__photo {
    max-width: 20rem;
    max-height: 15rem;
  }
  .slide--4 .issue__photo,
  .slide--5 .issue__photo {
    margin-bottom: 3rem;
  }
  .slide--6 .issue__photo {
    margin-bottom: 5rem;
  }
  .issue__text {
    font-size: 2rem;
  }
  .dots {
    bottom: 41%;
  }
  .slider__btn--right {
    transform: translate(25%, -200%);
  }
  .slider__btn--left {
    transform: translate(-25%, -200%);
  }
  .about-img-box {
    display: none;
  }
  .about-text-box {
    grid-column: 1/-1;
  }
  .contact-text-box {
    grid-column: 1/-1;
  }
  .contact-img-box {
    display: none;
  }
}

@media (max-width: 780px) {
  .footer-heading {
    margin-bottom: 1.5rem;
  }
}
@media (max-width: 740) {
  .dots {
    bottom: 37%;
  }
  .contact-form {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 550px) {
  .copyright {
    margin-top: 1rem;
  }
  .slider {
    padding: 0;
  }
}

@media (max-width: 1280) {
  .btn-mobile-nav {
    display: block;
    z-index: 3;
  }

  .main-nav {
    background-color: rgb(253, 214, 98, 0.9);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(10px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transform: translateX(100%);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }

  .main-nav-link:link,
  .main-nav-link:visited {
    color: #885432;
  }

  .main-nav-link:hover,
  .main-nav-link:active {
    color: #ddd;
  }

  .main-nav .nav-cta {
    background-color: #885432;
    color: #ddd;
  }

  .main-nav-list li:nth-child(5) {
    margin-right: 0;
  }

  .nav-open .main-nav {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0);
  }

  .nav-open .icon-mobile-nav[name="close-outline"] {
    display: block;
  }

  .nav-open .icon-mobile-nav[name="menu-outline"] {
    display: none;
  }

  .main-nav-list {
    flex-direction: column;
    gap: 4.8rem;
  }

  .main-nav-link:link,
  .main-nav-link:visited {
    font-size: 3rem;
  }
}

@media (max-width: 414px) {
  .slide--2 .issue__photo,
  .slide--3 .issue__photo,
  .slide--4 .issue__photo {
    margin-bottom: 5rem;
  }
  .slide--5 .issue__photo {
    margin-bottom: 3rem;
  }
  .slide--6 .issue__photo {
    margin-bottom: 7rem;
  }
  .dots {
    bottom: 30%;
  }
  .contact-text-box {
    padding: 3rem;
  }
  .contact-text {
    white-space: normal;
  }
  .contact,
  .contact-form {
    gap: 1rem;
  }
  .btn--form {
    grid-column: 1/-1;
  }
}

@media (max-width: 390px) {
  .slide--3 .issue__photo,
  .slide--4 .issue__photo {
    margin-bottom: 3rem;
  }
  .slide--5 .issue__photo {
    margin-bottom: 3rem;
  }
  .slide--6 .issue__photo {
    margin-bottom: 5rem;
  }
  .dots {
    bottom: 30%;
  }
  .contact-text {
    white-space: normal;
  }
  .contact,
  .contact-form {
    gap: 1rem;
  }
  .btn--form {
    grid-column: 1/-1;
  }
}

@media (max-width: 375px) {
  .slide--3 .issue__photo,
  .slide--4 .issue__photo {
    margin-bottom: 3rem;
  }

  .slide--2 .issue__photo,
  .slide--5 .issue__photo {
    margin-bottom: 5rem;
  }
  .slide--6 .issue__photo {
    margin-bottom: 7rem;
  }
  .dots {
    bottom: 26%;
  }
}
